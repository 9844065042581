import {
  IDeveloperSelectOption,
  ITableMessage,
  ITableMessages,
  ITableMessageInternal,
  IAllHooks,
} from "../interfaces";
import { SerializedHooks } from "../interfaces/api";
import { SelectOptionOverrides } from "../store/reducers/coredata";
import {
  FieldsStateWithSnapshots,
  IFieldsReducerState,
  SnapshotName,
} from "../store/reducers/fields";
import { addTableMessage } from "../thunks/data_actions";

type MapEntries<M extends Map<any, any>> = [
  M extends Map<infer K, any> ? MapSetToEntries<K> : never,
  M extends Map<any, infer V> ? MapSetToEntries<V> : never
][];

type SetEntries<S extends Set<any>> = (S extends Set<infer T>
  ? MapSetToEntries<T>
  : never)[];

type MapSetToEntries<T> = T extends Map<any, any>
  ? MapEntries<T>
  : T extends Set<any>
  ? SetEntries<T>
  : T;

type SerializedMapSets<T> = {
  [Prop in keyof T]: T[Prop] extends Map<any, any>
    ? MapEntries<T[Prop]>
    : T[Prop] extends Set<any>
    ? SetEntries<T[Prop]>
    : T[Prop];
};

export type ISerializedFieldState = SerializedMapSets<IFieldsReducerState>;

export type ISerializedFieldStateSnapshots = ISerializedFieldState & {
  snapshots: [name: SnapshotName, snapshot: ISerializedFieldState][];
};

export type ISerializedSelectMapping = [
  colIndex: number,
  mappings: [rawValue: string, mappedOption: IDeveloperSelectOption][]
][];

export type ISerializedSelectOptionOverrides = [
  optionMapEntries: [label: string, value: string][],
  overrideCells: [colIdx: number, rowIdxs: number[]][]
][];

export type ISerializedUserMessages = (ITableMessage & { cells: string[] })[];

export const serializeFields = (
  s: IFieldsReducerState
): ISerializedFieldState => {
  return {
    fieldOrder: s.fieldOrder,
    virtualFields: s.virtualFields,
    addedEmptyFields: [...s.addedEmptyFields.values()],
    fieldSpecs: [...s.fieldSpecs.entries()],
    columnMapping: [...s.columnMapping.entries()],
    selectFieldMapping: [...s.selectFieldMapping.entries()].map(
      ([k, fieldMap]) => [k, [...fieldMap.entries()]]
    ),
    dateFixMapping: [...s.dateFixMapping.entries()],
    ignoredColumns: [...s.ignoredColumns.values()],
    removedFields: [...s.removedFields.entries()],
  };
};

export const deserializeFields = (
  s: ISerializedFieldState
): IFieldsReducerState => {
  return {
    fieldOrder: s.fieldOrder,
    virtualFields: s.virtualFields,
    addedEmptyFields: new Set(s.addedEmptyFields),
    fieldSpecs: new Map(s.fieldSpecs),
    columnMapping: new Map(s.columnMapping),
    selectFieldMapping: new Map(
      s.selectFieldMapping.map(([k, entries]) => [k, new Map(entries)])
    ),
    dateFixMapping: new Map(s.dateFixMapping),
    ignoredColumns: new Set(s.ignoredColumns),
    removedFields: new Map(s.removedFields),
  };
};

export const serializeFieldsWithSnapshots = (
  s: FieldsStateWithSnapshots
): ISerializedFieldStateSnapshots => {
  return {
    ...serializeFields(s),
    snapshots: [...s.snapshots].map(([name, snapshot]) => [
      name,
      serializeFields(snapshot),
    ]),
  };
};

export const serializeSelectOptionOverrides = (
  selectOptionOverrides: SelectOptionOverrides
): ISerializedSelectOptionOverrides => {
  const inverted = invertOverrideMap(selectOptionOverrides);

  return [...inverted].map(([optionMap, overrideCells]) => [
    [...optionMap],
    overrideCells,
  ]);
};

export const deserializeSelectOptionOverrides = (
  serialized: ISerializedSelectOptionOverrides
): SelectOptionOverrides => {
  const overrides: SelectOptionOverrides = new Map();

  for (const [optionMapEntries, overrideCells] of serialized) {
    const optionsMap = new Map(optionMapEntries);

    for (const [fieldKey, rowIdxs] of overrideCells) {
      if (!overrides.has(fieldKey)) {
        overrides.set(fieldKey, new Map());
      }

      for (const rowIdx of rowIdxs) {
        overrides.get(fieldKey)!.set(rowIdx, optionsMap);
      }
    }
  }

  return overrides;
};

export const serializeUserMessages = (
  tableMessages: ITableMessages
): ISerializedUserMessages => {
  const userMessages = new Map<string, string[]>();

  for (const [rowIndex, rowMessages] of tableMessages) {
    for (const [colIndex, messages] of rowMessages) {
      for (const message of messages) {
        if (message.type !== "user-generated") continue;

        const serializedMessage = JSON.stringify({
          level: message.level,
          message: message.message,
        });

        const cell = `${rowIndex},${colIndex}`;

        if (userMessages.has(serializedMessage)) {
          userMessages.get(serializedMessage)!.push(cell);
        } else {
          userMessages.set(serializedMessage, [cell]);
        }
      }
    }
  }

  const serializedMessages: ISerializedUserMessages = [];
  for (const [messageJson, cells] of userMessages) {
    const message = JSON.parse(messageJson) as ITableMessage;
    serializedMessages.push({ ...message, cells });
  }

  return serializedMessages;
};

export const deserializeUserMessages = (
  serializedMessages: ISerializedUserMessages
): ITableMessages => {
  const tableMessages: ITableMessages = new Map();

  for (const { cells, ...message } of serializedMessages) {
    const internalMessage: ITableMessageInternal = {
      type: "user-generated",
      ...message,
    };

    for (const cell of cells) {
      const [rowIndex, colIndex] = cell.split(",").map((v) => parseInt(v));
      addTableMessage(tableMessages, rowIndex, colIndex, internalMessage);
    }
  }

  return tableMessages;
};

function invertOverrideMap(
  overrideMap: SelectOptionOverrides
): Map<Map<string, string>, [number, number[]][]> {
  const inverted = new Map<Map<string, string>, [number, number[]][]>();

  for (const [colIdx, fieldOverrides] of overrideMap.entries()) {
    const fieldMap = new Map<Map<string, string>, number[]>();

    for (const [rowIdx, selectMap] of fieldOverrides) {
      if (fieldMap.has(selectMap)) {
        fieldMap.get(selectMap)!.push(rowIdx);
      } else {
        fieldMap.set(selectMap, [rowIdx]);
      }
    }

    for (const [selectMap, rowIdxs] of fieldMap) {
      if (inverted.has(selectMap)) {
        inverted.get(selectMap)!.push([colIdx, rowIdxs]);
      } else {
        inverted.set(selectMap, [[colIdx, rowIdxs]]);
      }
    }
  }

  return inverted;
}

// Danger!
// This function does a good ol' fashioned eval.
// The provided src string gets immediately run in the global scope.
// Besides checking that the string evaluates to a function definition,
// no other type checking is possible, so you are implicitly trusting the source
// to conform to whatever type you provide
// tl;dr don't use this unless you don't care about getting pwned
export const dangerouslyDeserializeFunction = (src: string): any => {
  // eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
  const func = new Function(`"use strict"; return (${src})`)();
  if (typeof func !== "function") {
    throw new Error("Function src did not evaluate to a function");
  }

  return func;
};

const hookArrays = ["rowHooks", "bulkRowHooks", "rowDeleteHooks"] as const;

const singleHooks = ["beforeFinishCallback"] as const;

export const deserializeHooks = (serialized: SerializedHooks): IAllHooks => {
  const hooks: IAllHooks = {};
  for (const hookType of hookArrays) {
    if (!(hookType in serialized)) continue;
    hooks[hookType] = serialized[hookType]!.map(dangerouslyDeserializeFunction);
  }

  for (const hookType of singleHooks) {
    if (!(hookType in serialized)) continue;
    hooks[hookType] = dangerouslyDeserializeFunction(serialized[hookType]!);
  }

  if ("columnHooks" in serialized) {
    hooks.columnHooks = serialized.columnHooks!.map(
      ({ fieldName, callback }) => ({
        fieldName,
        callback: dangerouslyDeserializeFunction(callback),
      })
    );
  }

  if ("stepHooks" in serialized) {
    hooks.stepHooks = serialized.stepHooks!.map(({ type, callback }) => ({
      type,
      callback: dangerouslyDeserializeFunction(callback),
    }));
  }

  return hooks;
};
