import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../store/hooks";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import StepContainer from "../commonComponents/StepContainer";
import { SelectMatchCard } from "./SelectMatchCard";
import DateFixCard from "./DateFixCard";
import { Card } from "../commonComponents/Card";
import { getLabelsFromFields } from "../../helpers/CoreDataHelpers";
import {
  selectMappedSpecs,
  selectMappedSelectSpecs,
  selectMappedDateInstances,
} from "../../store/selectors";
import { useStepNavigation } from "../../thunks/step_navigation";
import { tryDateParse, DateParseResult } from "../../helpers/date_magic";
import { useDataContext } from "../DataContext";
import Toggle from "../commonComponents/Toggle";
import HelpText from "../HelpText";
import { ISelectField } from "../../interfaces";

export function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

const isExactMatchOnly = (field: ISelectField): boolean => {
  return (
    Array.isArray(field.type) &&
    "exactMatchOnly" in field.type[1] &&
    !!field.type[1].exactMatchOnly
  );
};

export function EnhanceData() {
  const { canGoBack, goBack, goToNextStep } = useStepNavigation();
  const {
    valCountsInColumn,
    mappedSpecs,
    mappedSelectFields,
    mappedDateInstances,
    stepSettings,
    dateFixEnabled,
  } = useAppSelector((state) => ({
    valCountsInColumn: state.coredata.data.valCountsInColumn,
    mappedSpecs: selectMappedSpecs(state),
    mappedSelectFields: selectMappedSelectSpecs(state),
    mappedDateInstances: selectMappedDateInstances(state),
    stepSettings: state.settings.matchValuesStep,
    dateFixEnabled: !!state.settings.backendCapabilities.auto_date_fix,
  }));

  const [filterMatched, setFilterMatchedColumns] = useState(false);

  const handleToggleFilterMatched = () => {
    setFilterMatchedColumns(!filterMatched);
  };

  const fields = [...mappedSpecs].map(([colIndex, field]) => ({
    colIndex,
    field,
  }));

  const { t } = useTranslation();

  const getValues = (columnIndex: number): string[] => {
    return Array.from(valCountsInColumn?.get(columnIndex)?.keys() || []).filter(
      (val) => val.trim() !== ""
    );
  };

  const labels = getLabelsFromFields(fields.map((f) => f.field));

  const { fullData } = useDataContext();

  const dateFixCols = useMemo(() => {
    const dateFixCols = new Map<number, DateParseResult>();
    if (!dateFixEnabled) {
      return dateFixCols;
    }

    for (const [colIndex, field] of mappedDateInstances) {
      const colData = fullData
        .map((row) => row[colIndex])
        .filter((v) => typeof v === "string" && v !== "") as string[];

      const parseResult = tryDateParse(colData);
      if (!parseResult) continue;

      const numAlreadyFormatted = colData
        .map((v) => {
          const result = field.transform(v);
          return (
            !result.empty &&
            result.success &&
            field.getDisplayValue(result.value) === v
          );
        })
        .filter((x) => x).length;

      if (colData.length - numAlreadyFormatted > 1) {
        dateFixCols.set(colIndex, parseResult);
      }
    }

    return dateFixCols;
  }, [mappedDateInstances, fullData, dateFixEnabled]);

  // We could land on this step with nothing to do, because we don't know if date fields
  // are actually fixable until we run the date fix code above. In that case, skip ahead
  // TODO: handle case where we have mapped select fields, but they were automapped or
  // had a full exact match so don't need anything, AND we had date cols but none were fixable.
  // In this case, we should skip this step, but we won't currently
  useEffect(() => {
    if (mappedSelectFields.size === 0 && dateFixCols.size === 0) {
      goToNextStep(true);
    }
  }, [mappedSelectFields, dateFixCols, goToNextStep]);

  return (
    <>
      <StepContainer
        data-cy="CleanDataModal"
        onBack={canGoBack && goBack}
        step="matchValues"
      >
        <div className="flex gap-2 items-end">
          <div className="flex-1">
            <Text type="h1" className="mb-2">
              {t("dataCleaningModal.title")}
            </Text>
            <Text type="body">{t("dataCleaningModal.subtitle")}</Text>
          </div>
          <div className="flex-0">
            <div className="flex gap-2 items-center self-end">
              <Toggle
                onChange={handleToggleFilterMatched}
                checked={filterMatched}
                disabled={false}
                value="showUnmatchedToggle"
                label={t("columnMatchModal.showUnmatchedToggle")}
                data-cy="match-values-show-unmatched-toggle"
              />
            </div>
          </div>
        </div>

        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}

        <Card className="my-4 mt-6">
          <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
            {fields.map(({ colIndex, field }, ordIndex) => {
              if (mappedSelectFields.has(colIndex)) {
                const exactMatchOnly = isExactMatchOnly(field as ISelectField);

                return (
                  <SelectMatchCard
                    key={colIndex}
                    name={labels[ordIndex]}
                    options={(field as ISelectField).selectOptions}
                    values={getValues(colIndex)}
                    columnIndex={colIndex}
                    filterMatched={filterMatched}
                    exactMatchOnly={exactMatchOnly}
                  />
                );
              }

              if (dateFixCols.has(colIndex)) {
                const parseResult = dateFixCols.get(colIndex)!;

                return (
                  <DateFixCard
                    colIndex={colIndex}
                    parseResult={parseResult}
                    key={colIndex}
                  />
                );
              }

              return undefined;
            })}
          </div>
        </Card>
        <div className="flex justify-end items-center gap-2 w-full mt-6">
          <Button
            onClick={() => goToNextStep()}
            data-cy="auto-clean-continue-button"
            autoFocus
          >
            {t("dataCleaningModal.continue")}
          </Button>
        </div>
      </StepContainer>
    </>
  );
}
